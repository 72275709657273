const dev = {
    API_ENDPOINT_URL: process.env.NEXT_PUBLIC_API_ENDPOINT_URL,
    API_YANDEX_KEY: process.env.NEXT_PUBLIC_API_YANDEX_KEY,
    API_ADMIN_URL: process.env.NEXT_PUBLIC_API_ADMIN_URL,
}

const prod = {
    API_ENDPOINT_URL: process.env.NEXT_PUBLIC_API_ENDPOINT_URL,
    API_YANDEX_KEY: process.env.NEXT_PUBLIC_API_YANDEX_KEY,
    API_ADMIN_URL: process.env.NEXT_PUBLIC_API_ADMIN_URL,
}

const test = {
    API_ENDPOINT_URL: process.env.NEXT_PUBLIC_API_ENDPOINT_URL,
    API_YANDEX_KEY: process.env.NEXT_PUBLIC_API_YANDEX_KEY,
    API_ADMIN_URL: process.env.NEXT_PUBLIC_API_ADMIN_URL,
}

const getEnv = () => {
    switch (process.env.NODE_ENV) {
        case 'development':
            return dev
        case 'production':
            return prod
        case 'test':
            return test
        default:
            break
    }
}

export const env = getEnv()

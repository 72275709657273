import { cookiePersistStorage } from '@/shared/storage'
import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'

export type SectionsStoreTypes = {
    version: string
    accessToken: string | null
    email?: string | null;
    unique_name?: string | null;
    role?: 'Tourist' | 'Partner' | 'Admin'
    setRole: (role: SectionsStoreTypes['role']) => void
    setVersion: (version: SectionsStoreTypes['version']) => void
    setAccessToken: (accessToken: SectionsStoreTypes['accessToken']) => void
    setEmail: (email: SectionsStoreTypes['email']) => void
    setUniqueName: (unique_name: SectionsStoreTypes['unique_name']) => void
    clear: () => void
}

export const useUserState = create(
    persist<SectionsStoreTypes>(
        (set, get) => {
            return {
                version: '1.0.1',
                accessToken: null,
                setRole: (role) => {
                    set({ role })
                },

                setAccessToken: (accessToken) => {
                    set({ accessToken })
                },
                setVersion: (version) => {
                    set({ version })
                },
                setEmail: (email) => {
                    set({ email })
                },
                setUniqueName: (unique_name) => {
                    set({ unique_name })
                },
                    clear: () => {
                    set({ accessToken: null, role: undefined })
                },
            }
        },
        {
            name: 'user-state',
            storage: createJSONStorage(() => cookiePersistStorage),
        },
    ),
)

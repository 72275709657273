import { API_BASE_URL } from '@/config/AppConfig'
import { Locale } from '@/i18n'
import { notFound } from 'next/navigation'

export const attractionService = {
    async getAttractions(
        locale: string,
        page = 1,
        limit = 3,
    ): Promise<AttractionListType | null> {
        const response = await fetch(
            `${API_BASE_URL}/attraction?page=${page}&take=${limit}&recordSize=${limit}`,
            {
                next: { revalidate: 60 },
                headers: {
                    'x-accept-lang': locale,
                },
            },
        )
        if (!response.ok) {
            return null
        }

        const data = await response.json()

        console.log(data)

        return data
    },

    async getAttractionBySlug(
        slug: string,
        locale: Locale,
    ): Promise<AttractionItemType> {
        const response = await fetch(`${API_BASE_URL}/attraction/${slug}`, {
            next: { revalidate: 60 },
            headers: {
                'x-accept-lang': locale,
            },
        })
        if (!response.ok) {
            notFound()
        }
        const data = await response.json()
        return data
    },
}

import { API_BASE_URL } from '@/config/AppConfig'
import { Locale } from '@/i18n'
import { notFound } from 'next/navigation'

export const entertainmentService = {
    async getEntertainments(
        locale: Locale,
        page = 1,
        limit = 10,
    ): Promise<EntertainmentListType | null> {
        const response = await fetch(
            `${API_BASE_URL}/entertainment?page=${page}&take=${limit}`,
            {
                next: { revalidate: 60 },
                headers: {
                    'x-accept-lang': locale,
                },
            },
        )
        if (!response.ok) {
            return null
        }
        const data = await response.json()

        return data
    },

    async getEntertainmentBySlug(
        slug: string,
        locale: Locale,
    ): Promise<EntertainmentItemType> {
        const response = await fetch(`${API_BASE_URL}/entertainment/${slug}`, {
            next: { revalidate: 60 },
            headers: {
                'x-accept-lang': locale,
            },
        })
        if (!response.ok) {
            notFound()
        }
        const data = await response.json()

        return data
    },
    async getProgramsByEntertainmentId(
        id: number,
        locale: Locale,
    ): Promise<ProgramListType[]> {
        const response = await fetch(
            `${API_BASE_URL}/EntertainmentProgram/${id}`,
            {
                next: { revalidate: 60 },
                headers: {
                    'x-accept-lang': locale,
                },
            },
        )
        if (!response.ok) {
            return []
        }
        const data = await response.json()

        return data
    },
    async getEntertainmentTypes(
        locale: Locale,
    ): Promise<EntertainmentTypeListType[]> {
        const response = await fetch(`${API_BASE_URL}/entertainmentType`, {
            next: { revalidate: 60 },
            headers: {
                'x-accept-lang': locale,
            },
        })
        if (!response.ok) {
            notFound()
        }
        const data = await response.json()

        return data
    },
    async getEntertainmentsByFilter(
        locale: Locale,
        filters: Record<string, string>,
        page: number = 1,
        pageSize: number = 10,
    ): Promise<EntertainmentListType> {
        // Adjust the return type according to your data structure
        // Convert filters object to URL search params
        const searchParams = new URLSearchParams()
        Object.entries(filters).forEach(([key, value]) => {
            searchParams.append(key, value)
        })
        searchParams.append('page', page.toString())
        searchParams.append('pageSize', pageSize.toString())

        const response = await fetch(
            `${API_BASE_URL}/entertainment/filter?${searchParams.toString()}`,
            {
                next: { revalidate: 60 },
                headers: {
                    'x-accept-lang': locale,
                },
            },
        )
        if (!response.ok) {
            return notFound()
        }
        const data = await response.json()

        return data
    },
}

interface PageData {
    id: number;
    title: string;
    url: string;
  }
  export const pageRuData: PageData[] = [
    {
      id: 1,
      title: "Туры",
      url: "/tury",
    },
    {
      id: 2,
      title: "Отели",
      url: "/hotels",
    },
    {
      id: 3,
      title: "Рестораны",
      url: "/restorany",
    },
    {
      id: 4,
      title: "Развлечения",
      url: "/razvlecheniya",
    },
    {
      id: 5,
      title: "Достопримечательности",
      url: "/dostoprimechatelnosti",
    },
    {
      id: 6,
      title: "События",
      url: "/sobytiya",
    },
  ];
  
  export const pageEnData: PageData[] = [
    {
      id: 1,
      title: "Tours",
      url: "/tury",
    },
    {
      id: 2,
      title: "Hotels",
      url: "/hotels",
    },
    {
      id: 3,
      title: "Restaurants",
      url: "/restorany",
    },
    {
      id: 4,
      title: "Entertainments",
      url: "/razvlecheniya",
    },
    {
      id: 5,
      title: "Attractions",
      url: "/dostoprimechatelnosti",
    },
    {
      id: 6,
      title: "Events",
      url: "/sobytiya",
    },
  ];
  
  export const pageCnData: PageData[] = [
    {
      id: 1,
      title: "旅游",
      url: "/tury",
    },
    {
      id: 2,
      title: "酒店",
      url: "/hotels",
    },
    {
      id: 3,
      title: "餐厅",
      url: "/restorany",
    },
    {
      id: 4,
      title: "娱乐",
      url: "/razvlecheniya",
    },
    {
      id: 5,
      title: "景点",
      url: "/dostoprimechatelnosti",
    },
    {
      id: 6,
      title: "活动",
      url: "/sobytiya",
    },
  ];
import { API_BASE_URL } from '@/config/AppConfig'
import { Locale } from '@/i18n'
import { TourTypeListType } from '@/types/mixed.types'
import { notFound } from 'next/navigation'

export const tourService = {
    async getTours(
        locale: Locale,
        limit = 10,
        page = 1,
    ): Promise<TourListType> {
        const response = await fetch(
            `${API_BASE_URL}/tour?page=${page}&take=${limit}`,
            {
                next: { revalidate: 60 },
                headers: {
                    'x-accept-lang': locale,
                },
            },
        )
        if (!response.ok) {
            notFound()
        }
        const data = await response.json()

        return data
    },
    async getTourTypes(locale: Locale): Promise<TourTypeListType[]> {
        const response = await fetch(`${API_BASE_URL}/tourType`, {
            next: { revalidate: 60 },
            headers: {
                'x-accept-lang': locale,
            },
        })
        if (!response.ok) {
            notFound()
        }
        const data = await response.json()

        return data
    },

    async getToursByFilter(
        locale: Locale,
        filters: Record<string, string>,
        page: number = 1,
        pageSize: number = 10,
    ): Promise<TourListType> {
        // Adjust the return type according to your data structure
        // Convert filters object to URL search params
        const searchParams = new URLSearchParams()
        Object.entries(filters).forEach(([key, value]) => {
            searchParams.append(key, value)
        })
        searchParams.append('page', page.toString())
        searchParams.append('pageSize', pageSize.toString())
        const response = await fetch(
            `${API_BASE_URL}/tour/filter?${searchParams.toString()}`,
            {
                next: { revalidate: 60 },
                headers: {
                    'x-accept-lang': locale,
                },
            },
        )
        if (!response.ok) {
            return notFound()
        }
        const data = await response.json()

        return data
    },
    async getTourBySlug(slug: string, locale: Locale): Promise<TourItemType> {
        const response = await fetch(`${API_BASE_URL}/tour/${slug}`, {
            next: { revalidate: 60 },
            headers: {
                'x-accept-lang': locale,
            },
        })
        if (!response.ok) {
            notFound()
        }
        const data = await response.json()

        return data
    },
    async getProgramsByTourId(
        id: number,
        locale: Locale,
    ): Promise<ProgramListType[]> {
        const response = await fetch(`${API_BASE_URL}/TourProgram/${id}`, {
            next: { revalidate: 60 },
            headers: {
                'x-accept-lang': locale,
            },
        })
        if (!response.ok) {
            return []
        }
        const data = await response.json()

        return data
    },
}

import { env } from '@/config/EnvironmentConfig'
import { MenuProps } from 'antd'
import Link, { LinkProps } from 'next/link'
import { ChevronDown, Icon } from 'react-feather'

export type NavListType = {
    text?: string
    path: string
    icon?: Icon | null
    langKey?: string
    children?: NavListType[]
}

export type ProfileItemType = {
    path: string
    langKey?: string
    isBlank?: boolean
}
export const mainNavigationItems: NavListType[] = [
    {
        path: '#',
        text: 'Отдых',
        langKey: 'Rest',
        icon: ChevronDown,
        children: [
            {
                path: '/dostoprimechatelnosti',
                text: 'Достопримечательности',
                langKey: 'Attractions',
            },
            {
                path: '/sobytiya',
                text: 'Календарь событий',
                langKey: 'Events',
            },
            {
                path: '/hotels',
                text: 'Где остановиться',
                langKey: 'Hotels',
            },
            {
                path: '/razvlecheniya',
                text: 'Развлечения',
                langKey: 'Entertainment',
            },
            {
                path: '/restorany',
                text: 'Рестораны',
                langKey: 'Restaurants',
            },
        ],
    },
    {
        path: '/about-us',
        text: 'О Якутии',
        icon: null,
        langKey: 'AboutYakutia',
    },
    {
        path: '/tury',
        text: 'Туры',
        icon: null,
        langKey: 'Tours',
    },
    {
        path: '/novosti',
        text: 'Новости',
        icon: null,
        langKey: 'News',
    },
    {
        path: '/interaktivnaya-karta',
        text: 'Интерактивная карта',
        icon: null,
        langKey: 'InteractiveMap',
    },
]

export const profileItemsNoAccessToken: ProfileItemType[] = [
    {
        langKey: 'register',
        path: '/register',
    },
    {
        langKey: 'login',
        path: '/login',
    },
]

export const profileItemsWithAccessTokenForTourist: ProfileItemType[] = [
    {
        langKey: 'profileText',
        path: '/moy-profayl',
    },
    {
        langKey: 'logout',
        path: '/logout',
    },
]

export const profileItemsWithAccessTokenForPartner: ProfileItemType[] = [
    {
        langKey: 'profileText',
        path: env!.API_ADMIN_URL!,
        isBlank: true,
    },
    {
        langKey: 'logout',
        path: '/logout',
    },
]

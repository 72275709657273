"use client";
import React, { useState } from "react";
import { Search } from "react-feather";
import SearchModal from "../search-overlay/SearchOverlay";

const HeadingSearch = () => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  const showModal = (): void => setIsModalVisible(true);
  const hideModal = (): void => setIsModalVisible(false);
  return (
    <>
      <span style={{cursor:"pointer"}} onClick={showModal}>
        <Search />
      </span>
      <SearchModal isModalVisible={isModalVisible} hideModal={hideModal} />
    </>
  );
};

export default HeadingSearch;

import { attractionService } from '@/components/page-modules/attraction/data/services/attraction.service';
import { entertainmentService } from '@/components/page-modules/entertainment/data/services/entertainment.service';
import { hotelService } from '@/components/page-modules/hotel/data/services/hotel.service';
import { restaurantService } from '@/components/page-modules/restaurant/data/services/restaurant.service';
import { tourService } from '@/components/page-modules/tours/data/services/tour.service';

export const searchItems = async (query: string, locale: string) => {
    const page = 1;
    const limit = 50;
    const filters = {};

    const [attractions, restaurants, hotels, entertainments, tours] = await Promise.all([
        attractionService.getAttractions(locale, page, limit),
        restaurantService.getRestaurants(locale, page, limit),
        hotelService.getHotels(locale, page, limit),
        entertainmentService.getEntertainments(locale, page, limit),
        tourService.getToursByFilter(locale, filters),
    ]);

    const allItems = [
        ...(attractions?.data || []).map((item: AttractionListItem) => ({ id: item.id, url: 'dostoprimechatelnosti/' + item.slug, title: item.title, searchName: item.title.toLowerCase() })),
        ...(restaurants?.data || []).map((item: RestaurantListItem) => ({ id: item.id, url: 'restorany/' + item.slug, title: item.title, searchName: item?.title.toLowerCase() })),
        ...(hotels?.data || []).map((item: HotelListItem) => ({ id: item.id, url: 'hotels/' + item.slug, title: item.heading, searchName: item?.heading.toLowerCase() })),
        ...(entertainments?.data || []).map((item: EntertainmentListItem) => ({ id: item.id, url: 'razvlecheniya/' + item.slug, title: item.companyName, searchName: item.companyName?.toLowerCase() })),
        ...(tours?.data || []).map((item: TourListItem) => ({ id: item.id, url: 'tury/' + item.slug, title: item.title, searchName: item.title?.toLowerCase() })),
    ];


    return allItems.filter(item =>
        item?.searchName.includes(query.toLowerCase())
    );
};
'use client'
import React, { FC, useMemo } from 'react'
import { useTranslations } from 'next-intl'
import Link from 'next/link'
import { Dropdown, Space, Button } from 'antd'
import { ChevronDown, User } from 'react-feather'
import {
    profileItemsNoAccessToken,
    profileItemsWithAccessTokenForPartner,
    profileItemsWithAccessTokenForTourist,
} from './data/header.repository'
import { SectionsStoreTypes, useUserState } from '@/store/user.store'

const ProfileDropdownMenu: FC<{
    accessToken: string | null
    role?: SectionsStoreTypes['role']
    locale: string
}> = ({ accessToken, role, locale }) => {
    const clear = useUserState((state) => state.clear)
    const t = useTranslations('profile')
    const items = useMemo(() => {
        if (!accessToken) return profileItemsNoAccessToken
        return role === 'Partner' || role === 'Admin'
            ? profileItemsWithAccessTokenForPartner
            : profileItemsWithAccessTokenForTourist
    }, [accessToken, role])

    const logoutHandler = () => {
        // handle logout logic here
        clear();
    }

    const menuItems = items.map((item) => {
        // Check if the item is for logout
        if (item.path === '/logout') {
            return {
                key: item.path,
                label: (
                    // Use a button for logout instead of a link
                    <Button
                        type="text"
                        onClick={logoutHandler}
                    >
                        {t(item.langKey)}
                    </Button>
                ),
            }
        }

        // For all other items, return a Link
        return {
            key: item.path,
            label: (
                <Link
                    target={item.isBlank ? '_blank' : '_self'}
                    href={
                        item.isBlank
                            ? `${item.path}/auto-login/${accessToken}`
                            : `/${locale}${item.path}`
                    }
                >
                    {t(item.langKey)}
                </Link>
            ),
        }
    })

    return (
        <Dropdown
            menu={{ items: menuItems }}
            overlayClassName="customClassNameForDropdown"
        >
            <Space>
                <User />
                <ChevronDown />
            </Space>
        </Dropdown>
    )
}

export default ProfileDropdownMenu

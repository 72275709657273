'use client'
import React, { useEffect, useMemo, useState, useTransition } from 'react'
import { useMediaQuery } from 'react-responsive'
import MobileHeader from './MobileHeader'
import DesktopHeader from './DesktopHeader'
import { usePathname, useRouter } from 'next/navigation'
import { useLocale } from 'next-intl'
import { defaultLocale, Locale, supportedLocales } from '@/i18n'
import Cookies from "js-cookie";

import styles from './header.module.scss'

type HeaderType = {
    cls?: string
    locale: string
}

const Header: React.FC<HeaderType> = ({ cls, locale }) => {
    const [scrollTop, setScrollTop] = useState(false)
    //register event listener when component mounts
    useEffect(() => {
        window.addEventListener('scroll', scrollHandler)
        // the function returned from here will run on unmounting
        return function () {
            window.removeEventListener('scroll', scrollHandler)
        }
    }, [])

    function scrollHandler() {
        window.scrollY >= 140 || cls === 'fixed'
            ? setScrollTop(true)
            : setScrollTop(false)
    }

    function findClassName(cls?: string) {
        if (cls === 'fixed_black') return 'fixed_black'
        if (cls === 'fixed') return 'fixed'
        if (cls === 'black') return 'black'
        return ''
    }

    const isMobileOrTablet = useMediaQuery({ maxWidth: '1200px' })

    const pathname = usePathname()
    const router = useRouter()
    const cLocale = useLocale()
    const [isPending, startTransition] = useTransition();

    const changeLocale = (l: Locale) => {
        const segments = pathname.split(cLocale);
        const currentPath = segments[segments?.length - 1];
        startTransition(() => {
          Cookies.set("NEXT_LOCALE", l);
          if (l === defaultLocale) {
            router.replace(`${currentPath || "/"}`);
          } else {
            router.replace(`/${l}/${currentPath}`);
          }
        });
      };
    const locales = useMemo(() => {
        return supportedLocales.map((locale) => ({
            value: locale,
            label: locale.toLocaleUpperCase(),
        }))
    }, [])

    const HeaderForResolution = useMemo(() => {
        return isMobileOrTablet ? MobileHeader : DesktopHeader
    }, [isMobileOrTablet])


    return (
        <header
            className={`${styles.header} ${cls ? styles[cls] : ''} ${
                scrollTop ? styles.headerScroll : ''
            } ${findClassName(cls)}`}
        >
            <HeaderForResolution
                cls={cls}
                handleChange={changeLocale}
                locales={locales}
            />
        </header>
    )
}

export default Header

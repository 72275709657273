import React from "react";
import styles from "./styles/base-container.module.scss";
type BaseContainerType = {
  children: React.ReactNode;
};
const BaseContainer: React.FC<BaseContainerType> = ({ children }) => {
  return <div className={styles.container}>{children}</div>;
};

export default BaseContainer;
